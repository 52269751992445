import React from "react";
import { Helmet } from "react-helmet";
import Button from "../components/elements/Button";
import { DefaultLayout } from "../components/layouts/DefaultLayout";
import CenteredHeroSection from "../components/sections/CenteredHeroSection";

export default function NotFoundPage() {
  return (
    <DefaultLayout>
      <Helmet title="404 Not Found"></Helmet>

      <CenteredHeroSection>
        <div className="text-center">
          <h1 className="text-5xl sm:text-8xl lg:text-9xl mb-4 font-extrabold text-red-500">
            404
          </h1>

          <h2 className="text-5xl sm:text-5xl lg:text-6xl mb-4 font-extrabold text-red-500">
            Not Found
          </h2>

          <h3 className="text-2xl text-gray-800 mb-6 dark:text-gray-200">
            Well this is awkward, seem like you tried to visit a page that
            doesn't exist.
          </h3>

          <Button href="/" aria-label="Back to start">
            Back to start
          </Button>
        </div>
      </CenteredHeroSection>
    </DefaultLayout>
  );
}
